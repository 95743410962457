import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  imports: [
    MatTooltipModule,
    MatButtonModule,
    MatRippleModule,
    MatIconModule,
  ],
  exports: [
    MatTooltipModule,
    MatButtonModule,
    MatRippleModule,
    MatIconModule,
  ],
})
export class ButtonsMaterialModule {
}
